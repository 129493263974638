import React, { useState, useEffect } from 'react';
import { Table, Spin, Alert, Button, Input } from 'antd';
import { API_BASE_URL } from '../config';

// Interface for the Order
interface Order {
  id: number;
  order_date: string;
  game_id: number;
  total_price: string;
  account_id: string;
  account_name?: string;
  account_password?: string;
  steam_guard_code?: string;
}

interface Game {
  id: number;
  title: string;
}

interface Account {
  account_name: string;
  password: string;
}

// Orders Component
export const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [requestingInfo, setRequestingInfo] = useState<number | null>(null); // Track which order is requesting info
  const [steamGuardCode, setSteamGuardCode] = useState<string | null>(null); // Store the code

  // Fetch orders from API
  const fetchOrders = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/users/me/orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error('No orders found');
      }
      const ordersData = await response.json();
      setOrders(ordersData);
      // Extract game IDs from orders
      const gameIds = ordersData.map((order: Order) => order.game_id);
      fetchGames(gameIds);
    } catch (err: any) {
      setError(err.message);
    }
  };

  // Fetch games from API
  const fetchGames = async (gameIds: number[]) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/v1/games/bulk?game_ids=${gameIds.join('&game_ids=')}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch game titles');
      }
      const gamesData = await response.json();
      setGames(gamesData);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Convert game IDs to titles
  const getGameTitle = (gameId: number) => {
    const game = games.find((g) => g.id === gameId);
    return game ? game.title : 'Unknown Game';
  };

  // Fetch account info by account_id
  const fetchAccountInfo = async (accountId: string, orderId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/game-accounts/${accountId}/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch account info');
      }
      const accountData: Account = await response.json();
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId
            ? { ...order, account_name: accountData.account_name, account_password: accountData.password }
            : order
        )
      );
      setRequestingInfo(null); // Reset requesting info state
    } catch (err: any) {
      setError(err.message);
      setRequestingInfo(null); // Reset requesting info state
    }
  };

  const requestSteamGuardCode = async (accountId: string, orderId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/game-accounts/${accountId}/me/request-steam-guard-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to request Steam Guard code');
      }
      const data = await response.json();
      await checkCodeStatus(data.request_id, accountId, orderId);
      alert('Steam Guard code requested');
    } catch (err: any) {
      setError(err.message);
    }
  };


  const checkCodeStatus = async (request_id: string, account_id: string, orderId: number) => {
    const interval = setInterval(async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/steam-guard/${request_id}/status`, {
          method: 'GET',
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (response.status === 200) {
          clearInterval(interval); // Stop polling when the code is completed
          await getSteamGuardCode(request_id, account_id, orderId); // Fetch the completed code
        }
      } catch (err) {
        setError("Failed to check Steam Guard status. Please try again or contact support.");
        clearInterval(interval); // Stop polling on error
      }
    }, 2000); // Poll every 2 seconds
  };

  const getSteamGuardCode = async (request_id: string, account_id: string, orderId: number) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/steam-guard/${request_id}/me?account_id=${account_id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId
              ? { ...order, steam_guard_code: data.code } // Update the steam guard code for the specific order
              : order
          )
        );
      } else {
        setError("Error fetching Steam Guard code. Please try again or contact support.");
      }
    } catch (err) {
      setError("Failed to retrieve Steam Guard code. Please try again or contact our support.");
    }
  };



  useEffect(() => {
    fetchOrders();
  }, []);

  // Columns for the Ant Design table
  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Date',
      dataIndex: 'order_date',
      key: 'order_date',
    },
    {
      title: 'Item',
      key: 'game_ids',
      render: (_: any, record: Order) => getGameTitle(record.game_id),
    },
    {
      title: 'Total',
      dataIndex: 'total_price',
      key: 'total_price',
    },
    {
      title: 'Account Info',
      key: 'account_id',
      render: (_: any, record: Order) => {
        return (
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* Account Name Input */}
              <Input
                value={record.account_name || '********'}
                disabled
                type={record.account_name ? "text" : "password"}  // Switch to text if account data is fetched
                style={{
                  backgroundColor: '#f0f0f0',
                  color: '#000', // Optional to ensure color contrast
                }}
              />
              {/* Account Password Input */}
              <Input
                value={record.account_password || '********'}
                disabled
                type={record.account_password ? "text" : "password"}  // Switch to text if account data is fetched
                style={{
                  backgroundColor: '#f0f0f0',
                  color: '#000', // Optional to ensure color contrast
                }}
              />

              {/* Steam Guard Code Input - Displayed above the buttons */}
              {record.steam_guard_code && (
                <Input
                  value={record.steam_guard_code || '********'}
                  disabled
                  type="text"
                  style={{
                    backgroundColor: '#f0f0f0',
                    color: '#000', // Optional to ensure color contrast
                    marginBottom: '10px' // Adds some space before the buttons
                  }}
                />
              )}

              {/* Buttons */}
              {requestingInfo === record.id ? (
                <Spin size="small" />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    setRequestingInfo(record.id);
                    fetchAccountInfo(record.account_id, record.id);
                  }}
                >
                  Reveal Info
                </Button>
              )}
              <Button
                type="default"
                onClick={() => {
                  setSteamGuardCode(null); // Clear any previous code
                  requestSteamGuardCode(record.account_id, record.id); // Trigger the request for the specific order
                }}
                style={{ marginLeft: '10px' }}
              >
                Request Steam Guard Code
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" showIcon />;
  }

  return (
    <div>
      <h2>Order History</h2>
      <Table columns={columns} dataSource={orders} rowKey="id" />
    </div>
  );
};

export default Orders;
