import React, { useState } from 'react';
import { Table, Tag, Button, Modal } from 'antd';

// Define the type for ticket data
type Ticket = {
  key: string;
  ticketID: string;
  date: string;
  status: string;
  chatHistory: {
    message: string;
    sender: string;
    time: string;
  }[];
};

export const Tickets: React.FC = () => {
  // Example data for order history
  const [tickets] = useState<Ticket[]>([
    {
      key: '1',
      ticketID: '123456',
      date: '2024-09-01',
      status: 'Closed',
      chatHistory: [
        { message: 'Issue reported', sender: 'User', time: '10:00 AM' },
        { message: 'Issue resolved', sender: 'Support', time: '11:00 AM' },
      ],
    },
    {
      key: '2',
      ticketID: '789012',
      date: '2024-08-15',
      status: 'Open',
      chatHistory: [
        { message: 'Question about order', sender: 'User', time: '02:00 PM' },
      ],
    },
    // Add more orders here
  ]);

  // State to manage selected ticket and visibility of chat history
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Columns for the table
  const columns = [
    {
      title: 'Ticket ID',
      dataIndex: 'ticketID',
      key: 'ticketID',
      render: (ticketID: string, record: Ticket) => (
        <Button type="link" onClick={() => handleTicketClick(record)}>
          {ticketID}
        </Button>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        let color = status === 'Open' ? 'red' : 'green';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
  ];

  // Handle ticket click to show chat history
  const handleTicketClick = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setIsModalVisible(true);
  };

  // Handle closing the modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedTicket(null);
  };

  return (
    <div>
      <Table columns={columns} dataSource={tickets} />

      {/* Modal to show chat history */}
      <Modal
        title={`Chat History for Ticket ${selectedTicket?.ticketID}`}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        style={{ backdropFilter: 'blur(10px)' }}
      >
        <div style={{ padding: '10px' }}>
          {selectedTicket?.chatHistory.map((chat, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <strong>{chat.sender} ({chat.time}):</strong>
              <p>{chat.message}</p>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Tickets;
