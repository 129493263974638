import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [resetToken, setResetToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('resetToken');
    if (token) setResetToken(token);
  }, [location.search]);

  const handleResetPassword = async (values: { password: string }) => {
    if (!resetToken) {
      message.error('Reset token is missing.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/users/reset_password/${resetToken}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: values.password }),
      });

      if (response.ok) {
        message.success('Password has been reset successfully.');
        navigate('/login');
      } else {
        message.error('Failed to reset password. Please try again.');
      }
    } catch (error) {
      message.error('An error occurred while resetting the password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Reset Password</h2>
      <Form layout="vertical" onFinish={handleResetPassword}>
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password' },
            { 
              pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
              message: 'Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, and one number.'
            }
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Enter new password" />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirm_password"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your new password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm new password" />
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={loading} block>
          Reset Password
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
