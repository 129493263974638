import React from 'react';
import { useUser } from './UserContext'; // Import the context hook

type WithAuthProps = {
  children?: React.ReactNode;
};

const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const AuthenticatedComponent: React.FC<P & WithAuthProps> = (props) => {
    const { user, loading } = useUser(); // Use the user context

    if (loading) {
      return <div>Loading...</div>;
    }

    return <WrappedComponent {...(props as P)} user={user} />;
  };

  return AuthenticatedComponent;
};

export default withAuth;
